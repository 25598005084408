// @ts-strict-ignore
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Carousel, CarouselItem, CarouselIndicators } from 'reactstrap';
const items = [{
  src: 'https://images.hu-production.be/static/img/home/HUhomepage.jpg'
}, {
  src: 'https://images.hu-production.be/static/img/home/homepage2.jpg'
}, {
  src: 'https://images.hu-production.be/static/img/home/homepage3.jpg',
  bgPosition: 'center top'
}];
export default function ReactstrapCarousel() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  useEffect(() => {
    if (window.innerWidth > 768) {
      setIsMobile(false);
    }
  }, []);
  const next = () => {
    if (isAnimating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (isAnimating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (isAnimating) return;
    setActiveIndex(newIndex);
  };
  return isMobile ? <MobileFallbackImage data-sentry-element="MobileFallbackImage" data-sentry-component="ReactstrapCarousel" data-sentry-source-file="carousel.tsx" /> : <StyledCarousel activeIndex={activeIndex} className="carousel-fade" interval={3000} next={next} previous={previous} ride="carousel" data-sentry-element="StyledCarousel" data-sentry-component="ReactstrapCarousel" data-sentry-source-file="carousel.tsx">
          <CarouselIndicators activeIndex={activeIndex} items={items} onClickHandler={goToIndex} data-sentry-element="CarouselIndicators" data-sentry-source-file="carousel.tsx" />

          {items.map(item => <StyledCarouselItem key={item.src} onExited={() => setIsAnimating(false)} onExiting={() => setIsAnimating(true)}>
              <BgImg style={{
        backgroundImage: `url(${item.src})`,
        backgroundPosition: item.bgPosition
      }} />
            </StyledCarouselItem>)}
        </StyledCarousel>;
}
const Positioning = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({
  theme
}) => theme.colorBlack};

  ::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.35);
    z-index: 1;
  }
`;
const StyledCarousel = styled(Carousel)`
  ${Positioning};

  .carousel-indicators {
    display: none;
  }

  .carousel-inner {
    position: absolute;
    height: 100%;
  }
`;
const StyledCarouselItem = styled(CarouselItem)`
  height: 100%;
`;
const BgImg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const MobileFallbackImage = styled.div`
  ${Positioning};
  background-image: url('https://images.hu-production.be/static/img/home/HUhomepage-mobile-1178-876.jpg');
  background-size: cover;
  background-position: center;
`;